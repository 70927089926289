import React from "react";

const WhatsApp = () => {
  return (
    <a
      rel="noreferrer"
      target="_blank"
      href="https://wa.link/tltg5c"
      className="btn btn-success p-2 btn-whatsapp"
    >
      <i className="fab fa-whatsapp"></i>
    </a>
  );
};
export default WhatsApp;
