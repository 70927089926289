import { Link } from "@reach/router";
import React from "react";

const Navbar = () => {
  const isActive = (link) => {
    return window.location.pathname.includes(link);
  };

  const activeLinkStyle = {
    color: "#0d6efd",
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark fixed-top mw-100 shadow-sm"
      style={{ backgroundColor: "#27272b" }}
    >
      <div className="container-fluid navbar-container">
        <Link className="navbar-brand p-2" to="/">
          <img
            src="https://tbmcerts.s3.us-west-1.amazonaws.com/established.png"
            className="logo-navbar"
            alt="logo"
          />{" "}
          <span className="ms-3 francois">The Body Method</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse mw-100" id="navbarNav">
          <div className="container-fluid d-flex justify-content-between">
            <div></div>
            <ul className="navbar-nav nav">
              <li className={`nav-item ${isActive("about")}`}>
                {window.location.pathname !== "/" ? (
                  <a
                    href="http://localhost:3000/#about-video"
                    className="nav-link"
                    to="/"
                  >
                    Conócenos
                  </a>
                ) : (
                  <a href="#about-video" className="nav-link" to="/">
                    Conócenos
                  </a>
                )}
              </li>
              <li className={`nav-item ${isActive("presencial")}`}>
                <Link
                  className="nav-link"
                  to="/live"
                  style={
                    isActive("live") ? activeLinkStyle : { color: "white" }
                  }
                >
                  En Vivo
                </Link>
              </li>
              <li className={`nav-item ${isActive("online")}`}>
                <Link
                  className="nav-link"
                  to="/ondemand"
                  style={
                    isActive("ondemand") ? activeLinkStyle : { color: "white" }
                  }
                >
                  On Demand
                </Link>
              </li>
              {/*<li className={`nav-item ${isActive("rutinas")}`}>
                <Link
                  className="nav-link"
                  to="/rutinas"
                  style={
                    isActive("rutinas") ? activeLinkStyle : { color: "white" }
                  }
                >
                  Rutinas
                </Link>
                </li>*/}
              <li className={`nav-item ${isActive("tbmOnline")}`}>
                <a
                  className="nav-link"
                  href="https://thebodymethod.mx"
                  target="_blank"
                >
                  TBM Online
                </a>
              </li>
              {/*<li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  href="https://shop.thebodymethod.mx"
                >
                  Shop
                </a>
                </li>*/}
            </ul>
            <div>
              <Link
                className="btn btn-sm btn-outline-blue bold my-1 text-uppercase"
                to="/entrar"
              >
                <i className="fa fa-user me-1"></i> Ingresar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
