import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import success from "../assets/images/exito.png";
import QuizCertificate from "./QuizCertificate";
import QuizFeedback from "./QuizFeedback";
import { navigate } from "@reach/router";

const QuizApproved = ({ attempt, questions, answers, course, test }) => {
  const { user } = useContext(AuthContext);

  const renderButton = () => {
    if (test.course_id === null) {
      return (
        <div className="d-flex h-100 flex-column justify-content-center">
          <button
            onClick={() => navigate(`/mytbm/course/${test.course_id}`)}
            className="btn shadow bg-dark btn-quiz"
          >
            {test.course_id !== null
              ? "CONTINUAR"
              : "CONTINUAR CON SIGUIENTE MODULO"}
          </button>
        </div>
      );
    }
  };

  return (
    <div className="col-12 ps-5 px-5 mt-5 mb-5 d-flex flex-column justify-content-end align-items-center">
      <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12">
        <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12">
          <img src={success} className="col-lg-1 col-4" alt="felicidades" />
        </div>
        <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12 mt-lg-5 mt-3  mb-3">
          <span className="bold text-center text-accent h1">
            ¡Felicidades {user.name}!
          </span>
        </div>
        <div className="d-flex h-100 flex-column justify-content-center align-items-center col-12 mb-5 mt-3 ps-5 px-5">
          <span className="text-center h4 bold">
            Has Aprobado el {test.course_id !== null ? "" : "QUIZ"} {test.name}.
          </span>
        </div>
        <div className="container-fluid text-center">
          <h4 className="bold">Calificación</h4>
          <p className="display-3 fw-normal">{attempt.score}</p>
        </div>
        {renderButton()}
      </div>
      <div className="container">
        <QuizCertificate course={course} attempt={attempt} test={test} />
      </div>
      <h3 className="mt-5 mb-3">Resultados</h3>
      <QuizFeedback questions={questions} answers={answers} />
    </div>
  );
};

export default QuizApproved;
